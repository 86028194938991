import promenadeImg from "../../../images/commercial/ongoing/promenade/section-2-bg-min.jpg";
import constImg1 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/1.jpg";
import constImg2 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/2.jpg";
import constImg3 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/3.jpg";
import constImg4 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/4.jpg";
import constImg5 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/5.jpg";
import constImg6 from "../../../images/commercial/ongoing/promenade/project-status/june-2024/6.jpg";

import julyImg1 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/1.jpg";
import julyImg2 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/2.jpg";
import julyImg3 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/3.jpg";
import julyImg4 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/4.jpg";
import julyImg5 from "../../../images/commercial/ongoing/promenade/project-status/july-2024/5.jpg";

import AugustImg1 from "../../../images/commercial/ongoing/promenade/project-status/august-2024/1.jpg";
import AugustImg2 from "../../../images/commercial/ongoing/promenade/project-status/august-2024/2.jpg";
import AugustImg3 from "../../../images/commercial/ongoing/promenade/project-status/august-2024/3.jpg";
import AugustImg4 from "../../../images/commercial/ongoing/promenade/project-status/august-2024/4.jpg";

import septImg1 from "../../../images/commercial/ongoing/promenade/project-status/september-2024/1.jpg";
import septImg2 from "../../../images/commercial/ongoing/promenade/project-status/september-2024/2.jpg";
import septImg3 from "../../../images/commercial/ongoing/promenade/project-status/september-2024/3.jpg";
import septImg4 from "../../../images/commercial/ongoing/promenade/project-status/september-2024/4.jpg";

import october2024Img1 from "../../../images/commercial/ongoing/promenade/project-status/october-2024/1.jpg";
import october2024Img2 from "../../../images/commercial/ongoing/promenade/project-status/october-2024/2.jpg";
import october2024Img3 from "../../../images/commercial/ongoing/promenade/project-status/october-2024/3.jpg";
import october2024Img4 from "../../../images/commercial/ongoing/promenade/project-status/october-2024/4.jpg";

import november2024Img1 from "../../../images/commercial/ongoing/promenade/project-status/november-2024/1.jpg";
import november2024Img2 from "../../../images/commercial/ongoing/promenade/project-status/november-2024/2.jpg";
import november2024Img3 from "../../../images/commercial/ongoing/promenade/project-status/november-2024/3.jpg";
import november2024Img4 from "../../../images/commercial/ongoing/promenade/project-status/november-2024/4.jpg";

import december2024Img1 from "../../../images/commercial/ongoing/promenade/project-status/december-2024/1.jpg";
import december2024Img2 from "../../../images/commercial/ongoing/promenade/project-status/december-2024/2.jpg";
import december2024Img3 from "../../../images/commercial/ongoing/promenade/project-status/december-2024/3.jpg";
import december2024Img4 from "../../../images/commercial/ongoing/promenade/project-status/december-2024/4.jpg";
import december2024Img5 from "../../../images/commercial/ongoing/promenade/project-status/december-2024/5.jpg";
import december2024Img6 from "../../../images/commercial/ongoing/promenade/project-status/december-2024/6.jpg";

import int1 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-06-min.jpg";
import int2 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-07.jpg";
import int3 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-08.jpg";
import int4 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-09-min.jpg";
import int5 from "../../../images/commercial/ongoing/promenade/Interiors/Untitled-1-12.jpg";

import ext1 from "../../../images/commercial/ongoing/promenade/Exteriors/Untitled-1-02.jpg";
import ext2 from "../../../images/commercial/ongoing/promenade/Exteriors/Untitled-1-04.jpg";
import ext3 from "../../../images/commercial/ongoing/promenade/Exteriors/Untitled-1-19.jpg";
import ext4 from "../../../images/commercial/ongoing/promenade/Exteriors/Untitled-1-22.jpg";

export const ongoingdata = [
  {
    id: 1,
    order: null,
    is_active: true,
    is_custom: true,
    ribbon_text: "New",
    title: "Promenade",
    slug: "promenade",
    rera: "",
    tagline: "",
    banner: {
      childImageSharp: {
        fluid: {
          src: promenadeImg,
        },
      },
    },
    logo: "",
    brouchureUrl: "",
    bg: "",
    // "brouchureUrl": "/pdfs/brouchures/damera.pdf",
    // "bg": "../../../images/bgs/plutus.jpg",
    callUsNumber: "",
    chatCodeId: "",
    video: {
      type: "youtube",
      link: "",
    },
    short_desc:
      "Anna Salai, Little Mount, Guindy, Chennai, Tamil Nadu – 600032 ",
    footer_desc: "Modern, Iconic, Sustainable Commercial Marvel",
    gallery: [
      {
        title: "June 2024",
        images: [
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: constImg1,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: constImg2,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: constImg3,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: constImg4,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: constImg5,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: constImg6,
                srcSet: "",
              },
            },
          },
        ],
        videos: null,
      },
      {
        title: "July 2024",
        images: [
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: julyImg1,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: julyImg2,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: julyImg3,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: julyImg4,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: julyImg5,
                srcSet: "",
              },
            },
          },
        ],
        videos: null,
      },
      {
        title: "August 2024",
        images: [
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: AugustImg1,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: AugustImg2,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: AugustImg3,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: AugustImg4,
                srcSet: "",
              },
            },
          },
        ],
        videos: null,
      },
      {
        title: "September 2024",
        images: [
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: septImg1,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: septImg2,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: septImg3,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: septImg4,
                srcSet: "",
              },
            },
          },
        ],
        videos: null,
      },
      {
        title: "October 2024",
        images: [
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: october2024Img1,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: october2024Img2,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: october2024Img3,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: october2024Img4,
                srcSet: "",
              },
            },
          },
        ],
        videos: null,
      },
      {
        title: "November 2024",
        images: [
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: november2024Img1,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: november2024Img2,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: november2024Img3,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: november2024Img4,
                srcSet: "",
              },
            },
          },
        ],
        videos: null,
      },
      {
        title: "December 2024",
        images: [
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: december2024Img1,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: december2024Img2,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: december2024Img3,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: december2024Img4,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: december2024Img5,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: december2024Img6,
                srcSet: "",
              },
            },
          },
        ],
        videos: null,
      },
    ],
    gallery2: [
      {
        title: "Exterior",
        images: [
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext1,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext2,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext3,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: ext4,
                srcSet: "",
              },
            },
          },
        ],
        videos: null,
      },
      {
        title: "Interior",
        images: [
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: int1,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: int2,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: int3,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: int4,
                srcSet: "",
              },
            },
          },
          {
            childImageSharp: {
              fluid: {
                aspectRatio: 1.3333333333333333,
                base64: "",
                sizez: "",
                src: int5,
                srcSet: "",
              },
            },
          },
        ],
        videos: null,
      },
    ],
    sections: [
      {
        title: "",
        project_updates: [
          {
            title: "June 2024",
            images: [
              {
                childImageSharp: {
                  fluid: {
                    aspectRatio: 1.3333333333333333,
                    base64: "",
                    sizez: "",
                    src: promenadeImg,
                    srcSet: "",
                  },
                },
              },
            ],
            videos: null,
          },
        ],
      },
    ],
  },
];
